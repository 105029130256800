
// application endpoints

export const add_application = "add-application"
export const get_application = "get-applications"
export const update_application = "update-application"
export const delete_application = "delete-application"

//module endpoints 
export const add_module = "add-module"
export const get_module = "get-modules"
export const update_module = "update-module"
export const delete_module = "delete-module"

//role endpoints 

export const add_role = "add-role"
export const get_role = "get-roles"
export const update_role = "update-role"
export const delete_role = "delete-role"

// screen endpoints 

export const add_screen = "add-screen"
export const get_screen = "get-screens"
export const update_screen = "update-screen"

//user endpoints 

export const add_user = "add-user"
export const get_user = "get-users"
export const update_user = "update-user"
export const delete_user = "delete-user"
export const delete_user_2 = "api/delete-user"

//subcriptions apis

export const add_subcription = "add-subscription"
export const get_subcription = "get-subscriptions"
export const update_subcription = "update-subscription"
export const delete_subcription = "delete-subscription"

//Client api's 

export const add_client = "add-client"
export const get_client = "get-clients"
export const update_client = "update-client"
export const delete_client = "delete-client"

//Module-Screen api's 
export const get_module_screen = "get-module-screens"
export const add_module_screen = "add-module-screen"
export const update_module_sceen = "update-module-screen"

//Client Subcription apis'

export const add_client_subscription = "add-client-subscription"
export const get_client_subscription = "get-client-subscriptions"
export const update_client_subscription = "update-client-subscription"
export const delete_client_subscription = "delete-client-subscription"

//Json getting api 

export const get_json = "get-module-screen-json"

//channel master api 

export const get_channel_master = "pcms/get/channel"
export const create_channel_master = "pcms/create/channel"
export const update_channel_master = "pcms/update/channel"
export const delete_channel_master = "pcms/delete/channel"

//campaign master api

export const get_campaign_master = "pcms/get/campaign_master"
export const create_campaign_master = "pcms/create/campign_master"
export const update_campaign_master = "pcms/update/campaign"
export const delete_campaign_master = "pcms/delete/campaign_master"


// client campaign
export const client_campaign = "pcms/create/client_campaign"
export const update_client_campaign = "pcms/update/client_campaign"
export const get_client_campaign = "pcms/get/client_campaign"
export const delete_client_campaign="pcms/delete/client_campaign"

//transaction 
export const get_transaction="transaction/getTransaction"
export const create_transaction="transaction/createTransaction"
export const delete_transaction="transaction/deleteTransaction"

//Movement 
export const get_movement="momentMaster/getMovementMaster"
export const create_movement="momentMaster/createMomentType"
export const delete_movement="momentMaster/deleteMovement" 

//GST slab
export const gst_slab = "api/allMaster/v2/gst_slabs_master_api/"

//GST Treatment 
export const gst_treatment = "api/allMaster/v2/gst_treatment_api/"

//Orgnization type 
export const org_type = "api/allMaster/v2/organisation_type_api/"

// Company Type
export const company_type = "api/allMaster/v2/company_type_type_api/"

//Store type 

export const store_type = "api/allMaster/v2/store_type_api/"

//Status master 

export const status_api = "api/PO/v2/status_master_api/"

//Task Category 

export const taskCategory = "api/machine/v2/task_category_api/"            

//Document type 

export const document_type_api = "api/allMaster/v2/task_document_type_api/"

//  Scenario Mapping Master
export const scenario_mapping= "scenario/scenarioMapping"

// inspection type api
export const inspection_type = "api/inspection/inspection_type_master"

//HSN master
export const HSN_master="hsnmaster/hsn"

//Adjustment Master
export const adjustment_reason="api/adjustment_reason"

//Product type 
export const product_type="api/product/v2/product_type/"