import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";

import { Pagination } from "react-bootstrap";
import { Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import {
  get_movement_api,
  delete_movement_api,
  get_transaction_api,
  save_movement_api,
  get_store_type,
} from "../../api";
import { calMaxPage } from "../../common/Functions/CommonFunctions";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";
const schema = yup
  .object()
  .shape({
    movementCode: yup.string().required("Movement code is required !"),
    movementEffect: yup.string().required("Movement effect is required !"),
    transaction: yup
      .array()
      .required("Please select transaction !")
      .min(1, "Please select transaction !"),
      storetype: yup
      .array()
      .required("Please select store type !")
      .min(1, "Please select store type !"),
  })
  .required();
export function Movement() {
  const [page, setPage] = useState(0);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [visible, setVisible] = useState(false);
  const [transactionList, settransactionList] = useState({
    data: [],
    loading: false,
  });
  const [movementList, setmovementList] = useState({
    data: [],
    loading: false,
    totalRecords: "",
  });
  const [storelist,setstorelist]=useState({
    data:[],
    loading:false
  })

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  useEffect(() => {
    fetchmovements(page, entriesPerPage);
    fetchtransactions(0, 1000);
    storetypes()
  }, []);
  //   useEffect(() => {
  //     if (visible) {
  //       reset();
  //     }
  //   }, [visible]);

  function fetchmovements(a, b) {
    setmovementList({ ...movementList, data: [], loading: true });

    const payload = {
      entriesPerPage: b,
      pageNo: a,
    };

    // console.log("a",a)
    // console.log("b",b)
    get_movement_api(payload).then(
      (res) => {
        console.log("res", res.data.data);
        setmovementList({
          ...movementList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage(res.data.total));
      },
      (err) => {
        setmovementList({
          ...movementList,
          data: [],
          loading: false,
          totalRecords: "",
        });
        console.log("err", err);
      }
    );
  }

  function storetypes() {
    setstorelist({data:[],loading:true})
    

    get_store_type().then(
      (res) => {
        setstorelist({
         
          loading: false,
          data: res.data.data,
          
        });
       
      },
      (err) => {
        setstorelist({ loading: false, data: [] });
      }
    );
    

   
  }
 

  const delmovement = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure you want to delete \n" + `${val.movementCode}` + " ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_movement_api(val.uuid).then(
          (res) => {
            console.log("res", res);
            toast.success("Movement deleted successfully", {
              autoClose: 2000,
            });

            let value = movementList.totalRecords % 10;
            if (value == 1 && page !== 0) {
              fetchmovements(page - 1, 10);
              setPage(page - 1);
              setSrNo(srNo - 10);
            } else {
              fetchmovements(page, 10);
            }
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  const onSubmit = (data) => {
    console.log("data", data);
    if (data !== "") {
      const reqPayload = {
        movementCode: data.movementCode,
        movementDesc: data.movementDesc,
        movementEffect:data.movementEffect,
        store_type_id:data.storetype[0]?.uuid,
        store_type_name:data.storetype[0]?.name, 
        transaction:data.transaction[0]?.uuid
      };

      console.log("reqPayload", reqPayload);
 
      save_movement_api(reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Movement created successfully !", {
              autoClose: 2000,
            });
            fetchmovements(page, 10);
            setVisible(false);
            reset();
          }
        },
        (err) => {
            // console.log('err',err)
            // console.log('err',err.response.data.description.errors[0].message);
            if(err.response?.data?.statusCode==400&&err.response?.data?.description?.errors[0]?.message=="movement_code must be unique"){
              toast.error("Movment code already exists !", {
                autoClose: 2000,
              });
            }else{
              toast.error("Something went wrong !", {
                autoClose: 2000,
              });
            }
           
          
        }
      );
    } else {
      errors.showMessages();
    }
  };

  function fetchtransactions(a, b) {
    settransactionList({ ...transactionList, data: [], loading: true });

    const payload = {
      entriesPerPage: b,
      pageNo: a,
    };

    // console.log("a",a)
    // console.log("b",b)
    get_transaction_api(payload).then(
      (res) => {
        console.log("res", res.data.data);
        settransactionList({
          ...transactionList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        settransactionList({
          ...transactionList,
          data: [],
          loading: false,
          totalRecords: "",
        });
        console.log("err", err);
      }
    );
  }
  return (
    <React.Fragment>
      <ToastContainer />

      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Add Movement"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Movement Code
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="movementCode"
                  name="movementCode"
                  placeholder="Enter movement code"
                  {...register("movementCode")}
                />
                <span className="text-danger">
                  {errors.movementCode?.message}
                </span>
              </div>
            </div>
            
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Movement Effect
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="movementEffect"
                  name="movementEffect"
                  placeholder="Enter movement effect"
                  {...register("movementEffect")}
                />
                <span className="text-danger">
                  {errors.movementEffect?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                {"Transaction"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <Controller
                  name="transaction"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="transactionName"
                      size="sm"
                      multiple={false}
                      options={transactionList.data}
                      loading={transactionList.loading}
                      placeholder="Please select transaction"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.transaction ? true : false}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.transaction?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                {"Store Type"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <Controller
                  name="storetype"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="name"
                      size="sm"
                      multiple={false}
                      options={storelist.data}
                      loading={storelist.loading}
                      placeholder="Please select store type"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.storetype ? true : false}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.storetype?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Movement Description
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="movementDesc"
                  name="movementDesc"
                  placeholder="Enter movement description"
                  {...register("movementDesc")}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
      <div className="roles">
        <div className="d-flex justify-content-between">
          <h1 className="page-heading inter-bold">{"Movement"}</h1>
          <button
            className="btn btn-pink px-4"
            onClick={() => {
              setVisible(true);
              reset();
            }}
          >
            <FontAwesomeIcon
              style={{ color: "#fff" }}
              icon={faPlus}
              size="sm"
            />{" "}
            Create New Movement
          </button>
        </div>

        <div className="row">
          <div className="col-12">
            {/* <div className='entriesPerPage inter-bold d-flex align-items-end'>
                            <p className='mb-0'>Show
                                <select className='mx-1' defaultValue={'10'} onChange={(e) => {
                                    setEntriesPerPage(e.target.value);
                                    fetchmovements(page, e.target.value)
                                }}>
                                    <option value={'10'}>10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>Entries</p>

                        </div> */}
            <div className="card border-0 mt-3 mb-4">
              <div className="card-body">
                {movementList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : movementList.data?.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table mt-2 table-striped table-sm">
                      <thead className="table-grey  inter-bold">
                        <tr className="">
                          <th scope="col">{"Sr. No"}</th>
                          <th scope="col">{"Movement Code"}</th>
                          <th scope="col">{"Movement Description"}</th>
                          <th scope="col">{"Movement Effect"}</th>
                          <th scope="col">{"Store Type"}</th>
                          <th scope="col">{"Transaction"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody className=" inter">
                        {movementList.data.map((val, i) => (
                          <tr key={i}>
                            <td style={{ verticalAlign: "middle" }}>
                              {parseInt(i) + 1 + parseInt(srNo)}
                            </td>
                            <td>{val.movementCode}</td>
                            <td>
                              {val.movementDesc ? val.movementDesc : "NA"}
                            </td>
                            <td>
                              {val.movementEffect ? val.movementEffect : "NA"}
                            </td>
                            <td>
                              {val.store_type_name ? val.store_type_name : "NA"}
                            </td>
                            <td>
                              {val.transactionDetails?.transactionName ? val.transactionDetails?.transactionName : "NA"}
                            </td>

                            <td className="">
                              <Tooltip id={"delete-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"delete-tooltip" + i}
                                data-tooltip-content="Delete Movement"
                                onClick={() => delmovement(val)}
                                className="deleteIcon m-1 cursor_pointer"
                              >
                                <Trash2 color="#FFFFFF" size={18} />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-center text-danger mb-0">
                    No Movement's Found
                  </p>
                )}

                {movementList.loading ? null : movementList.data?.length > 0 ? (
                  <div className="mt-4 d-flex justify-content-between">
                    <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${
                      movementList.data.length +
                      entriesPerPage * (page + 1) -
                      entriesPerPage
                    } of ${movementList.totalRecords} entries`}</p>
                    <Pagination>
                      <Pagination.Prev
                        disabled={page === 0 ? true : false}
                        onClick={() => {
                          fetchmovements(page - 1, 10);
                          setSrNo((prevC) =>
                            page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                          );
                          setPage(page - 1);
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{page + 1}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                          page === maxPage ||
                          maxPage === 0 ||
                          entriesPerPage > movementList.data.length
                            ? true
                            : false
                        }
                        onClick={() => {
                          // console.log("parseInt(srNo) + parseInt(entriesPerPage)", parseInt(srNo) + parseInt(entriesPerPage));
                          fetchmovements(page + 1, 10);
                          setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                          setPage(page + 1);
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
