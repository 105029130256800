import React, { useState, useEffect, useReducer } from "react";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faBan,
    faFilter,
    faEye, faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Tooltip } from "react-tooltip";
import { Typeahead } from "react-bootstrap-typeahead";
import { delete_user_api, delete_user_api_2, get_app, get_client_api, get_role_api, get_user_api, save_user_api } from "../../api";
import { calMaxPage } from "../../common/Functions/CommonFunctions";
import EditUser from "./EditUser";
import { useNavigate } from "react-router-dom";

const schema = yup
    .object()
    .shape({
        name: yup
            .string()
            .required("Please enter name"),
        role: yup
            .array()
            .min(1, "Please select role")
            .required("Please select role"),
        app: yup
            .array()
            .required("Please select application !")
            .min(1, "Please select application !"),
        client: yup
            .array()
            .required("Please select client !")
            .min(1, "Please select client !"),
        email: yup
            .string()
            .required("Please enter email")
            .email("Please enter valid email"),
        username: yup
            .string()
            .required("Please enter username"),
        // .matches(/^[a-zA-Z0-9]*$/, "Only alphabets and number allowed !"),
        password: yup
            .string()
            .required("Please enter password")
            .matches(
                /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@$&])(?=.{8,})/,
                "1 uppercase, 1 number, 1 special character and atleast 8 character"
            ),
        confirmPassword: yup
            .string()
            .required("Please enter the same password")
            .oneOf([yup.ref("password"), null], "Passwords must match"),
    })
    .required();


function User() {

    const [visible, setVisible] = useState(false);

    const [apiBySearch, setApiBySearch] = useState(false);
    const [srNo, setSrNo] = useState(0);
    const [totalRecords, setTotalRecords] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [app_list, setApp_list] = useState({ data: [], loading: false });
    const [role_list, setRole_list] = useState({ data: [], loading: false });
    const [clientlist, setClientlist] = useState({ data: [], loading: false })
    const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    let { errors } = formState;

    const initialState = {
        loading: false,
        error: "",
        userList: [],
        itemsPerPage: 10,
        page: 0,
        maxPage: 0,
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "HANDLE_ACTION":
                return {
                    page: action.page,
                    itemsPerPage: action.itemsPerPage,
                    loading: action.loading,
                    error: action.error,
                    userList: action.userList,
                    maxPage: action.maxPage,
                };

            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        console.log("state", state);
        dispatch({
            type: "HANDLE_ACTION",
            page: state.page,
            loading: true,
            error: state.error,
            userList: state.userList,
            itemsPerPage: state.itemsPerPage,
            maxPage: state.maxPage,
        });

        fetchUser();
        fetch_app();
        fetchRole();
        fetchclient()
    }, [state.page, state.itemsPerPage]);

    function fetchUser() {
        dispatch({
            type: "HANDLE_ACTION",
            page:  state.page,
            loading: true,
            error: state.error,
            userList: state.userList,
            itemsPerPage: state.itemsPerPage,
            maxPage: state.maxPage,
        });

        var reqPayload = {
            offset:  state.page,
            limit: state.itemsPerPage,
        };

      

        //console.log("reqPayload", reqPayload);


        get_user_api(reqPayload).then(
            (res) => {
                console.log("User res", res);
                console.log(
                    "calMaxPage(res.data.data.total)",
                    // calMaxPage(res.data.total)
                    calMaxPage(res.data.data.response.total)
                );
                setTotalRecords(res.data.data.response.total);

                dispatch({
                    type: "HANDLE_ACTION",
                    page: state.page,
                    loading: false,
                    error: state.error,
                    userList: res.data.data.response.data,
                    itemsPerPage: state.itemsPerPage,
                    maxPage:calMaxPage(res.data.data.response.total),
                });
            },
            (err) => {
                console.log("err", err);

                dispatch({
                    type: "HANDLE_ACTION",
                    page: state.page,
                    loading: false,
                    error: state.error,
                    userList: [],
                    itemsPerPage: state.itemsPerPage,
                    maxPage: state.maxPage,
                });
                if (err.response.status === 500) {
                    toast.error("Something went wrong !", { autoClose: 5000 });
                }
            }
        );
    }
    const fetch_app = () => {
        setApp_list({ data: [], loading: true });

        get_app({}).then((res) => {
            console.log("res", res.data.data.response);
            setApp_list({ data: res.data.data.response, loading: false });
        })
            .catch((err) => {
                console.log("err", err);
                setApp_list({ data: [], loading: false });
            })
    }
    function fetchRole() {

        setRole_list({ data: [], loading: true })


        get_role_api({}).then(
            (res) => {
                console.log("Role res", res);
                setRole_list({ data: res.data.data.response.data, loading: false })

            },
            (err) => {
                console.log("err", err);

                setRole_list({ data: [], loading: false })
            }
        );
    }
    function fetchclient() {
        setClientlist({ data: [], loading: true });

        get_client_api({}).then(
            (res) => {
                console.log("client res", res);

                setClientlist({ data: res.data.data.response.data, loading: false });


            },
            (err) => {
                console.log("err", err);

                setClientlist({ data: [], loading: false });
            }
        );
    }

    // Deleting NC Status
    const delUser = (val) => {
        //console.log("val", );
        SweetAlert.fire({
            title: "Are you sure ?",
            text: "Once deleted, you will not be able to recover this record !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "cancel",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                // Firing delete api
                const payload = {
                    user_id: val.user_id,
                };
                console.log("asdjkl",payload)
                delete_user_api_2(payload).then(
                  (res) => {
                    console.log("res", res);
                    toast.success("User deleted successfully !", {
                      autoClose: 3000,
                    });

                    
                      fetchUser();
                   

                  },
                  (err) => {
                    console.log("err", err.response.data.data.statusCode);
                    if(err.response?.data?.data?.statusCode==400){
                        toast.error(err.response.data.data.response.message, { autoClose: 3000 });
                    }
                    else{
                        toast.error("Something went wrong !", { autoClose: 3000 });
                    }
                    
                  }
                );
            }
        });
    };

    function openNav() {
        document.getElementById("mySidebar").style.width = "320px";
        document.getElementById("root_div_main").style.marginLeft = "320px";
        setTimeout(() => {
            document.getElementById("mySidebar").style.overflowX = "visible";
        }, [200]);
    }

    function closeNav() {
        document.getElementById("mySidebar").style.width = "0";
        document.getElementById("root_div_main").style.marginLeft = "0";

        document.getElementById("mySidebar").style.overflowX = "hidden";
    }
    const onSubmit = (data) => {
        console.log("daa", data)
        if (data !== "") {

            const reqPayload = {
                username: data.username,
                email: data.email,
                password: data.password,
                client_id: data.client[0].client_id,
                role_name: data.role[0].name,
                role_id: data.role[0].role_id,
                app_id: data.app[0].app_id,
                name: data.name
            }

            console.log("reqpayload", reqPayload);

            save_user_api(reqPayload)
                .then(
                    (res) => {
                        console.log("res", res);
                        if (res.status === 200 || res.status === 201) {
                            toast.success("User created Successfully !", {
                                autoClose: 3000,
                            });
                            setVisible(false);
                            fetchUser();
                            reset();
                        }
                    },
                    (err) => {
                        console.log("err.response.status", err.response);
                        if (err.response.data.data.statusCode === 400) {
                            //onsole.log("err.response", err.response);
                            toast.error(err.response.data.data.response, {
                              autoClose: 2000,
                            });
                          }else{
                            toast.error("Something went wrong !", {
                              autoClose: 2000,
                            });
                          }
                    }
                )

        } else {
            errors.showMessages();
        }
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="min-vh-100" id="root_div_main">

                <div id="mySidebar" className="customsidebar ">
                    <a className="closebtn" onClick={() => closeNav()}>
                        ×
                    </a>

                    <div className=" content">
                        <div className="filter row">
                            <label
                                className="filterLabel mb-2 inter-bold"
                                style={{ fontSize: "14px" }}
                            >
                                Search
                            </label>
                            <div className="col-12">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    placeholder="Search by name"
                                    onChange={(e) => {
                                        if (e.target.value.length > 0) {
                                            fetchUser("search", e.target.value);
                                            setApiBySearch(true);
                                        } else {
                                            fetchUser();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="my-3 d-flex justify-content-end">
                            <button className="btn btn-sm btn-yellow">
                                <FontAwesomeIcon
                                    style={{ color: "#344454" }}
                                    icon={faBan}
                                    size="sm"
                                />{" "}
                                Clear Filter
                            </button>
                            {/* <button
                          className="btn btn-sm btn-grey ms-0 ms-3 px-4"
                      >
                          <FontAwesomeIcon style={{ color: "#fff" }} icon={faSearch} size="sm" />{" "}
                          Search
                      </button> */}
                        </div>
                    </div>
                </div>
                <div className="content-wrapper">
                    <div className="d-flex justify-content-between">
                        <label className="page-heading">{"Users"}</label>
                        <div>
                            {/* <button
                                className="btn btn-grey px-3 me-0 me-md-2"
                                onClick={() => {
                                    openNav();
                                }}
                            >
                                <FontAwesomeIcon
                                    style={{ marginRight: "5px" }}
                                    icon={faFilter}
                                    size="sm"
                                />
                                Filter Menu
                            </button> */}

                            <button
                                className="btn btn-pink px-4"
                                onClick={() => {
                                    setVisible(true);
                                    reset();
                                }}
                            >
                                <FontAwesomeIcon
                                    style={{ color: "#fff" }}
                                    icon={faPlus}
                                    size="sm"
                                />{" "}
                                {"Add User"}
                            </button>
                        </div>
                    </div>
                    <div className="card border-0 mt-3">
                        <div className="card-body">
                            <div className="d-flex justify-content-start mb-3 me-3">
                                {/* <div>
                                    <p className="mb-0">
                                        Show
                                        <select
                                            className="mx-1"
                                            defaultValue={"10"}
                                            onChange={(e) => {
                                                dispatch({
                                                    type: "HANDLE_ACTION",
                                                    page: 1,
                                                    loading: state.loading,
                                                    error: state.error,
                                                    userList: state.userList,
                                                    itemsPerPage: e.target.value,
                                                    maxPage: state.maxPage,
                                                })
                                                setSrNo(0);
                                            }

                                            }
                                        >
                                            <option value={"10"}>10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                        Entries
                                    </p>
                                </div> */}
                            </div>
                            <div className="">
                                {state.loading ? (
                                    <div className="text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : state.userList && state.userList.length > 0 ? (
                                    <div className="table-responsive">
                                        <table
                                            className="table table-striped table-sm"
                                            width={"100%"}
                                        >
                                            <thead className="table-grey text-ceter inter">
                                                <tr className="f-14">
                                                    <th scope="col" width="15%">
                                                        {"Sr. No"}
                                                    </th>
                                                    {/* <th scope="col">{"Name"}</th> */}
                                                    <th scope="col">{"Username"}</th>
                                                    <th scope="col">{"Email"}</th>
                                                    <th scope="col">{"Client"}</th>
                                                    
                                                    {/* <th scope="col">{"Mobile"}</th> */}
                                                    <th scope="col" width="15%">
                                                        {"Action"}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="inter">
                                                {state.userList.map((val, i) => (
                                                    <tr key={i}>
                                                        <td>{i + srNo + 1}</td>
                                                        {/* <td>{val.name}</td> */}
                                                        <td>{val.username}</td>
                                                        <td>{val.email}</td>
                                                        <td>{val.client?.company_name?val.client?.company_name:"NA"}</td>
                                                        <td>
                                                            {/* <EditUser
                                                                data={val}
                                                                fetchUser={fetchUser}
                                                                app_list={app_list.data}
                                                                role_list={role_list.data}
                                                                clientlist={clientlist.data}
                                                            /> */}
                                                            <Tooltip id={"del-tooltip" + i} place="top" />
                                                            <div
                                                                data-tooltip-id={"del-tooltip" + i}
                                                                data-tooltip-content="Delete User"
                                                                className="deleteIcon me-2"
                                                                onClick={() => delUser(val)}
                                                            >
                                                                <Trash2 color="#FFFFFF" size={18} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : apiBySearch ? (
                                    <p className="text-danger mb-0 f-22 text-center mt-4">
                                        {"No User found !"}
                                    </p>
                                ) : (
                                    <p className="text-danger mb-0 f-22 text-center mt-4">
                                        {"User list is empty"}
                                    </p>
                                )}

                                {state.loading ? null : (
                                    <div className="mt-4 d-flex justify-content-between">
                                        <p className='mb-0'>{`Showing ${parseInt(srNo) + 1} to ${Math.min(parseInt(state.itemsPerPage) + parseInt(srNo), totalRecords)} of ${totalRecords} entries`}</p>
                                        <Pagination>
                                            <Pagination.Prev
                                                disabled={Number(state.page) + 1 === 1 ? true : false}
                                                onClick={() => {
                                                    dispatch({
                                                        type: "HANDLE_ACTION",
                                                        page: state.page - 1,
                                                        loading: state.loading,
                                                        error: state.error,
                                                        userList: state.userList,
                                                        itemsPerPage: state.itemsPerPage,
                                                        maxPage: state.maxPage,
                                                    });
                                                    setSrNo((prevC) => prevC - parseInt(state.itemsPerPage));
                                                }}
                                            >
                                                {"Prev"}
                                            </Pagination.Prev>

                                            <Pagination.Item active>{Number(state.page) + 1}</Pagination.Item>

                                            <Pagination.Next
                                                disabled={
                                                    Number(state.page) + 1 === state.maxPage || state.maxPage === 0
                                                        ? true
                                                        : false
                                                }
                                                onClick={() => {
                                                    dispatch({
                                                        type: "HANDLE_ACTION",
                                                        page: state.page + 1,
                                                        loading: state.loading,
                                                        error: state.error,
                                                        userList: state.userList,
                                                        itemsPerPage: state.itemsPerPage,
                                                        maxPage: state.maxPage,
                                                    });
                                                    setSrNo((prevC) => prevC + parseInt(state.itemsPerPage));
                                                }}
                                            >
                                                {"Next"}
                                            </Pagination.Next>
                                        </Pagination>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={visible}
                    onHide={() => setVisible(false)}
                    size="lg"
                    backdrop="static"
                >
                    <Modal.Header
                        style={{ background: "#2A3643", color: "white" }}
                        closeButton
                    >
                        <Modal.Title>Create User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form action="" autoComplete="off">

                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label col-12 col-md-4"
                                        >
                                            Name
                                            <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <input
                                                type="text"
                                                className={errors.name ? "form-control form-control-sm is-invalid"
                                                    : " form-control form-control-sm"}
                                                id="name"
                                                name="name"
                                                placeholder="Enter name"
                                                {...register("name")}
                                            />
                                            {errors.name
                                                && <span className='text-danger err-msg'>{errors.name.message}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label col-12 col-md-4"
                                        >
                                            User Name
                                            <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <input
                                                type="text"
                                                className={errors.username ? "form-control form-control-sm is-invalid"
                                                    : " form-control form-control-sm"}
                                                id="username"
                                                name="username"
                                                placeholder="Enter user name"
                                                {...register("username")}
                                            />
                                            {errors.username
                                                && <span className='text-danger err-msg'>{errors.username.message}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label col-12 col-md-4"
                                        >
                                            Email
                                            <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <input
                                                type="email"
                                                className={errors.email ? "form-control form-control-sm is-invalid"
                                                    : " form-control form-control-sm"}
                                                id="email"
                                                name="email"
                                                placeholder="Enter email"
                                                {...register("email")}
                                            />
                                            {errors.email
                                                && <span className='text-danger err-msg'>{errors.email.message}</span>}

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label col-12 col-md-4"
                                        >
                                            Password
                                            <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">

                                            <div className="input-group">
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    className={errors.password ? "form-control form-control-sm is-invalid"
                                                        : " form-control form-control-sm"}
                                                    id="password"
                                                    name="password"

                                                    placeholder="Enter password"
                                                    {...register("password")}
                                                    autoComplete="new-password"
                                                />
                                                <span className="input-group-text">
                                                    {showPassword ?
                                                        <FontAwesomeIcon
                                                            style={{ color: "#212529", cursor: 'pointer' }}
                                                            icon={faEyeSlash}
                                                            size="sm"
                                                            onClick={() => setShowPassword(false)}
                                                        />
                                                        :
                                                        <FontAwesomeIcon
                                                            style={{ color: "#212529", cursor: 'pointer' }}
                                                            icon={faEye}
                                                            size="sm"
                                                            onClick={() => setShowPassword(true)}
                                                        />
                                                    }
                                                </span>

                                            </div>

                                            {errors.password
                                                && <span className='text-danger err-msg'>{errors.password.message}</span>}
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="form-label col-12 col-md-4"
                                        >
                                            Confirm Password
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <div className="input-group">
                                                <input
                                                    type={showPassword2 ? "text" : "password"}
                                                    className={errors.confirmPassword ? "form-control form-control-sm is-invalid"
                                                        : " form-control form-control-sm"}
                                                    id="confirmPassword"
                                                    name="confirmPassword"

                                                    placeholder="Enter confirm password"
                                                    {...register("confirmPassword")}
                                                    autoComplete="new-password"
                                                />

                                                <span className="input-group-text">
                                                    {showPassword2 ?
                                                        <FontAwesomeIcon
                                                            style={{ color: "#212529", cursor: 'pointer' }}
                                                            icon={faEyeSlash}
                                                            size="sm"
                                                            onClick={() => setShowPassword2(false)}
                                                        />
                                                        :
                                                        <FontAwesomeIcon
                                                            style={{ color: "#212529", cursor: 'pointer' }}
                                                            icon={faEye}
                                                            size="sm"
                                                            onClick={() => setShowPassword2(true)}
                                                        />
                                                    }
                                                </span>

                                            </div>
                                            {errors.confirmPassword
                                                && <span className='text-danger err-msg'>{errors.confirmPassword.message}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row mb-3">


                                        <label style={{ fontSize: "14px" }} className="form-label col-12 col-md-4">{"Application"}
                                            <span className="text-danger">
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <Controller
                                                name="app"
                                                control={control}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Typeahead
                                                        id="basic-typeahead"
                                                        labelKey="app_name"
                                                        size="sm"
                                                        multiple={false}
                                                        options={app_list.data}
                                                        loading={app_list.loading}
                                                        placeholder="Select application"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        isInvalid={errors.app ? true : false}
                                                    />
                                                )}
                                            />
                                            <span className="text-danger">{errors.app?.message}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row mb-3">


                                        <label style={{ fontSize: "14px" }} className="form-label col-12 col-md-4">{"Role"}
                                            <span className="text-danger">
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <Controller
                                                name="role"
                                                control={control}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Typeahead
                                                        id="basic-typeahead"
                                                        labelKey="name"
                                                        size="sm"
                                                        multiple={false}
                                                        options={role_list.data}
                                                        loading={role_list.loading}
                                                        placeholder="Select role"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        isInvalid={errors.role ? true : false}
                                                    />
                                                )}
                                            />
                                            <span className="text-danger">{errors.role?.message}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row mb-3">


                                        <label style={{ fontSize: "14px" }} className="form-label col-12 col-md-4">{"Client"}
                                            <span className="text-danger">
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <Controller
                                                name="client"
                                                control={control}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Typeahead
                                                        id="basic-typeahead"
                                                        labelKey="company_name"
                                                        size="sm"
                                                        multiple={false}
                                                        options={clientlist.data}
                                                        loading={clientlist.loading}
                                                        placeholder="Select client"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        isInvalid={errors.client ? true : false}
                                                    />
                                                )}
                                            />
                                            <span className="text-danger">{errors.client?.message}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-yellow px-4"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Save
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default User